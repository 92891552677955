let host = document.location.host;
let apiUrl = "https://skillprobackend.azurewebsites.net/";

const environment = {
  api: apiUrl,
  sasKey:
    "sp=racwdli&st=2024-05-01T12:17:30Z&se=2070-05-01T20:17:30Z&sv=2022-11-02&sr=c&sig=AQ7v4HhuDj%2BeA0pZHQEy2zATZKSwBMpjie%2BhvcaLc6Y%3D",
  sasurl: "https://skillprobackendstore.blob.core.windows.net",
  container: "exel",
  map_api_key: "AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg",
  planTypeId: "65ead4e65cfbfd7a03ed102f",
  apiUrl: "https://skillprobackend.azurewebsites.net/",
  userRoleId: "65eac23ceac028f4dbfb1fbc",
  customerRoleId: "66026ea2f105ac23ab31e48e",
  glRoleId: "65fc4a1e2e22cff912335e12",
  adminRoleId: "65eab1d84e01e43033dc2438",
  professionType: "65fa7ad79ef95c639effcf1c",
  productTypeId: "64a7d198fa039f179c0320ca",
  secretKey: "AFFJFHJKNJ8Y78989",
};
export default environment;
